import {
  getSalesCategoryScaleReport,
  getSalesCurrMonthReport,
  getSalesRankDistributorReport,
  getSalesRankStoreReport,
  exportStock,
  getSalesStatsReport
} from "./api.js";
import Util from "@/utils/utils";
import SalesTable from "./components/SalesTable";
import {
  blueSymbol,
  greenSymbol,
  orangeSymbol,
  redSymbol
} from "../components/images";
import SalesFormFiltrate from "./components/SalesFormFiltrate.vue";
import moment from "moment";

export default {
  components: {
    SalesTable,
    SalesFormFiltrate
  },
  data() {
    return {
      expLoading: false,
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/reports/sales",
          name: "purchase",
          title: "销售报表"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      columns1: [
        {
          label: "供应商",
          prop: "orgName",
          width: 90
        },{
          label: "品类",
          prop: "materialGroupName",
          width: 90
        },
        {
          label: "销售总额(元)",
          prop: "salesTotalAmountCount",
          width: 140
        },
        {
          label: "环比",
          prop: "salesTotalAmountHB",
          width: 80
        },
        {
          label: "同比",
          prop: "salesTotalAmountTB",
          width: 80
        },
        {
          label: "渠道销售额(元)",
          prop: "salesNormalAmountCount",
          width: 160
        },
        {
          label: "环比",
          prop: "salesNormalAmountHB",
          width: 80
        },
        {
          label: "同比",
          prop: "salesNormalAmountTB",
          width: 80
        },
        {
          label: "零售额(元)",
          prop: "salesRetailAmountCount",
          width: 120
        },
        {
          label: "环比",
          prop: "salesRetailAmountHB",
          width: 80
        },
        {
          label: "同比",
          prop: "salesRetailAmountTB",
          width: 80
        }
      ],
      columns2: [
        {
          label: "销售总量(台)",
          prop: "salesTotalQuantityCount",
          width: 130
        },
        {
          label: "环比",
          prop: "salesTotalQuantityHB",
          width: 80
        },
        {
          label: "同比",
          prop: "salesTotalQuantityTB",
          width: 80
        },
        {
          label: "渠道销售量(台)",
          prop: "salesNormalQuantityCount",
          width: 160
        },
        {
          label: "环比",
          prop: "salesNormalQuantityHB",
          width: 80
        },
        {
          label: "同比",
          prop: "salesNormalQuantityTB",
          width: 80
        },
        {
          label: "零售量(台)",
          prop: "salesRetailQuantityCount",
          width: 120
        },
        {
          label: "环比",
          prop: "salesRetailQuantityHB",
          width: 80
        },
        {
          label: "同比",
          prop: "salesRetailQuantityTB",
          width: 80
        }
      ],
      data: [],
      dates: [],
      terms: {},
      showLoading: false,
      salesCategoryScaleReportData: [],
      salesCurrMonthReport: [],
      salesRankDistributorReport: [],
      salesRankStoreReport: [],
      TableLength:1,
      currentPreviousDay:''
    };
  },
  created() {
    // this.getData();
    this.dates = [
      moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment()
        .endOf("month")
        .format("YYYY-MM-DD")
    ];
    this.getSalesCategoryScaleReportData();
  },
  mounted() {
    this.getYesterday()
  },

  methods: {
    getYesterday() {
      let day1 = new Date();
      day1.setTime(day1.getTime()-24*60*60*1000);
      this.currentPreviousDay = day1.getFullYear()+"/" + (day1.getMonth()+1) + "/" + day1.getDate();
    },
    onDateChange(date, dateString) {
      this.dates = dateString;
      this.getSalesCategoryScaleReportData();
    },
    initChart() {
      const pie = this.$echarts.init(this.$refs.pieChart);
      const data = this.salesCategoryScaleReportData;
      const pieOption = {
        title: [
          {
            text: "各品类总占比",
            textStyle: {
              color: "#999999",
              fontSize: 12,
              fontWeight: 400
            },
            textAlign: "center",
            x: "80",
            y: "36%"
          },
          {
            text: "100%",
            textStyle: {
              color: "#333333",
              fontSize: 18,
              fontWeight: 400
            },
            textAlign: "center",
            x: "80",
            y: "48%"
          }
        ],
        legend: {
          selectedMode: false,
          icon: "circle",
          // left: "50%",
          y: "center", //延Y轴居中
          // x: '10%', //居右显示
          orient: "vertical",
          color: "#ffffff",
          right: 20, //右边距0
          // top: this.$util.setFont(0),
          // icon: "pie",
          itemWidth: 8, // 设置宽度
          itemHeight: 8, // 设置高度
          // itemGap: this.$util.setFont(12), // 设置间距
          itemGap: 10, // 设置间距
          formatter: function(name) {
            var target;
            for (let i = 0, l = data.length; i < l; i++) {
              if (data[i].name == name) {
                target = data[i].scale;
              }
            }
            var str = "{a|" + name + "}{b|" + target + "}";
            return str;
          },
          textStyle: {
            rich: {
              a: {
                color: "#999999",
                fontSize: 12,
                width: 58,
                padding: [0, 0, 0, 2] //间距
              },
              b: {
                color: "#999999",
                fontSize: 12
              }
            }
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        textStyle: {
          fontFamily: "DIGIB"
        },
        label: {
          show: true,
          position: "center"
        },
        series: [
          {
            name: "库存总数",
            type: "pie",
            radius: ["55%", "75%"],
            center: ["24%", "50%"],
            // minAngle: 30, // 最小角度
            clockWise: false,
            // startAngle:210, //起始角度
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false
              }
            },

            labelLine: {
              show: false
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              },
              normal: {
                color: function(params) {
                  //自定义颜色
                  // var colorList = ["#5AD8A6", "#5B8FF9", "#5D7092", "#F6BD16", "#E8684A", "#6DC8EC"];
                  if (params.data && params.data.color) {
                    return params.data.color;
                  } else {
                    return "";
                  }
                }
              }
            },
            data: data
          }
        ]
      };
      pie.setOption(pieOption);
    },
    initChartRight() {
      const line = this.$echarts.init(this.$refs.lineChart);
      const option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(38,38,38,0.69)",
          borderWidth: 0,
          formatter: '{b}<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #5B8FF9"></span>{a0}: {c0}元' +
              '<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #5AD886"></span>{a1}: {c1}元' +
              '<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #FFA767"></span>{a2}: {c2}元',
          extraCssText: "min-width:140px!important;text-align: left;",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: 400,
            lineHeight: 20
          }
        },
        legend: {
          data: ["销售总额", "渠道销售额", "零售额"],
          right: 24,
          selectedMode: false,
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "12%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: (this.salesCurrMonthReport.map(it => it.date)).reverse()
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "销售总额",
            type: "line",
            data: (this.salesCurrMonthReport.map(it => it.totalAmount)).reverse(),
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: blueSymbol,
            color: "#5B8FF9",
            itmStyle: {
              color: "#5B8FF9"
            },
            lineStyle: {
              color: "#5B8FF9",
              shadowColor: "rgba(91,143,249,0.1)",
              shadowBlur: 1,
              shadowOffsetY: 4
            },
            emphasis: {
              symbol: "circle",
              symbolSize: 4
            }
          },
          {
            name: "渠道销售额",
            type: "line",
            data: (this.salesCurrMonthReport.map(it => it.normalAmount)).reverse(),
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: greenSymbol,
            color: "#5AD886",
            itmStyle: {
              color: "#5AD886"
            },
            lineStyle: {
              color: "#5AD886",
              shadowColor: "rgba(2,196,145,0.1)",
              shadowBlur: 1,
              shadowOffsetY: 4
            }
          },
          {
            name: "零售额",
            type: "line",
            data: (this.salesCurrMonthReport.map(it => it.retailAmount)).reverse(),
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: orangeSymbol,
            color: "#FFA767",
            itmStyle: {
              color: "#FFA767"
            },
            lineStyle: {
              color: "#FFA767",
              shadowColor: "rgba(255,167,103,0.1)",
              shadowBlur: 1,
              shadowOffsetY: 4
            }
          }
        ]
      };
      line.setOption(option);
      const line2 = this.$echarts.init(this.$refs.lineChart2);
      const option2 = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(38,38,38,0.69)",
          borderWidth: 0,
          formatter: '{b}<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #5B8FF9"></span>{a0}: {c0}台' +
              '<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #5AD886"></span>{a1}: {c1}台' +
              '<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #FFA767"></span>{a2}: {c2}台',
          extraCssText: "min-width:140px!important;text-align: left;",
          textStyle: {
            color: "#FFFFFF",
            fontWeight: 400,
            lineHeight: 20
          }
        },
        legend: {
          data: ["销售总量", "渠道销售量", "零售量"],
          right: 24,
          selectedMode: false,
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "12%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: (this.salesCurrMonthReport.map(it => it.date)).reverse()
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            name: "销售总量",
            type: "line",
            data: (this.salesCurrMonthReport.map(it => it.totalQty)).reverse(),
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: blueSymbol,
            color: "#5B8FF9",
            itmStyle: {
              color: "#5B8FF9"
            },
            lineStyle: {
              color: "#5B8FF9",
              shadowColor: "rgba(91,143,249,0.1)",
              shadowBlur: 1,
              shadowOffsetY: 4
            },
            emphasis: {
              symbol: "circle",
              symbolSize: 4
            }
          },
          {
            name: "渠道销售量",
            type: "line",
            data: (this.salesCurrMonthReport.map(it => it.normalQty)).reverse(),
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: greenSymbol,
            color: "#5AD886",
            itmStyle: {
              color: "#5AD886"
            },
            lineStyle: {
              color: "#5AD886",
              shadowColor: "rgba(2,196,145,0.1)",
              shadowBlur: 1,
              shadowOffsetY: 4
            }
          },
          {
            name: "零售量",
            type: "line",
            data: (this.salesCurrMonthReport.map(it => it.retailQty)).reverse(),
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: orangeSymbol,
            color: "#FFA767",
            itmStyle: {
              color: "#FFA767"
            },
            lineStyle: {
              color: "#FFA767",
              shadowColor: "rgba(255,167,103,0.1)",
              shadowBlur: 1,
              shadowOffsetY: 4
            }
          }
        ]
      };
      line2.setOption(option2);
    },
    initChartBottom1() {
      const bar = this.$echarts.init(this.$refs.barChart);
      const option = {
        tooltip: {
          trigger: "axis",
          formatter: '{b}<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #73A0FA"></span>{a0}: {c0}元' +
              '<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #5AD8A6"></span>{a1}: {c1}台',
          extraCssText: "min-width:140px!important;text-align: left;",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: ["销量金额", "销量汇总"],
          right: 24,
          selectedMode: false,
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "12%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          data: this.salesRankDistributorReport.map(it => it.dealerName).reverse(),
          axisLine: {
            lineStyle: {
              color: "#cccccc"
            }
          },
          axisLabel: {
            color: "#777777",
            formatter: function(value) {
              if (!value) {
                return "";
              }
              if (value.length > 5) {
                return value.substr(0, 5) + "...";
              } else {
                return value;
              }
            }
          }
        },
        series: [
          {
            name: "销量金额",
            type: "bar",
            data: (this.salesRankDistributorReport.map(it => it.salesAmount)).reverse(),
            barMaxWidth: 14,
            color: "#73A0FA",
            itemStyle: {
              color: "#73A0FA"
            }
          },
          {
            name: "销量汇总",
            type: "bar",
            barMaxWidth: 14,
            data: (this.salesRankDistributorReport.map(it => it.salesQty)).reverse(),
            color: "#5AD8A6",
            itemStyle: {
              color: "#5AD8A6"
            }
          }
        ]
      };
      bar.setOption(option);
    },
    initChartBottom2() {
      const bar2 = this.$echarts.init(this.$refs.barChart2);
      const option2 = {
        tooltip: {
          trigger: "axis",
          formatter: '{b}<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #7383A0"></span>{a0}: {c0}元' +
              '<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #FFA767"></span>{a1}: {c1}台',
          extraCssText: "min-width:140px!important;text-align: left;",
          axisPointer: {
            type: "shadow"
          }
        },
        legend: {
          data: ["销量金额", "销量汇总"],
          right: 24,
          selectedMode: false,
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          top: "12%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          data: (this.salesRankStoreReport.map(it => it.storeName)).reverse(),
          axisLine: {
            lineStyle: {
              color: "#cccccc"
            }
          },
          axisLabel: {
            color: "#777777",
            formatter: function(value) {
              if (!value) {
                return "";
              }
              if (value.length > 5) {
                return value.substr(0, 5) + "...";
              } else {
                return value;
              }
            }
          }
        },
        series: [
          {
            name: "销量金额",
            type: "bar",
            data: (this.salesRankStoreReport.map(it => it.salesAmount)).reverse(),
            barMaxWidth: 14,
            itemStyle: {
              color: "#7383A0"
            }
          },
          {
            name: "销量汇总",
            type: "bar",
            data: (this.salesRankStoreReport.map(it => it.salesQty)).reverse(),
            barMaxWidth: 14,
            itemStyle: {
              color: "#FFA767"
            }
          }
        ]
      };
      bar2.setOption(option2);
    },
    searchFun(arg) {
      this.terms = arg;
      this.pager.pageNo = 1;
      this.getData();
    },
    exportFile() {
      this.expLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          model: "",
          colour: "",
          warehouseId: "",
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: "",
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      const url = this.taoji
        ? "exportInventoryProductList&type=1"
        : "exportInventoryList&type=1";
      exportStock(data, url)
        .then(res => {
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "我的库存.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .catch(err => {
          this.expLoading = false;
        });
    },
    getData() {
      this.showLoading = true;
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 10
        },
        loginType: "CS"
      };
      if (!data.terms.orgCode) {
        data.terms.orgCode = "";
      }
      if (!data.terms.materialGroupCode) {
        data.terms.materialGroupCode = "";
      }
      getSalesCurrMonthReport(data)
        .then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          this.salesCurrMonthReport = data.report || [];
          this.initChartRight();
        })
        .finally(() => {
          this.showLoading = false;
        });
      data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 10
        },
        loginType: "CS"
      };
      if (!data.terms.orgCode) {
        data.terms.orgCode = "";
      }
      if (!data.terms.materialGroupCode) {
        data.terms.materialGroupCode = "";
      }
      getSalesRankDistributorReport(data)
        .then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          this.salesRankDistributorReport = data.report || [];
          this.initChartBottom1();
        })
        .finally(() => {
          this.showLoading = false;
        });
      getSalesRankStoreReport(data)
        .then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          this.salesRankStoreReport = data.report || [];
          this.initChartBottom2();
        })
        .finally(() => {
          this.showLoading = false;
        });
      getSalesStatsReport(data)
        .then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          const report = data.report||{};
          this.data = [];
          this.data.push({
            orgName: this.terms.orgName,
            materialGroupName: this.terms.materialGroupName,
            salesTotalAmountCount:report.salesTotalAmount&&report.salesTotalAmount.count,
            salesTotalAmountHB:report.salesTotalAmount&&report.salesTotalAmount.HB,
            salesTotalAmountTB:report.salesTotalAmount&&report.salesTotalAmount.TB,
            salesNormalAmountCount:report.salesNormalAmount&&report.salesNormalAmount.count,
            salesNormalAmountHB:report.salesNormalAmount&&report.salesNormalAmount.HB,
            salesNormalAmountTB:report.salesNormalAmount&&report.salesNormalAmount.TB,
            salesRetailAmountCount:report.salesRetailAmount&&report.salesRetailAmount.count,
            salesRetailAmountHB:report.salesRetailAmount&&report.salesRetailAmount.HB,
            salesRetailAmountTB:report.salesRetailAmount&&report.salesRetailAmount.TB,
            salesTotalQuantityCount:report.salesTotalQuantity&&report.salesTotalQuantity.count,
            salesTotalQuantityHB:report.salesTotalQuantity&&report.salesTotalQuantity.HB,
            salesTotalQuantityTB:report.salesTotalQuantity&&report.salesTotalQuantity.TB,
            salesNormalQuantityCount:report.salesNormalQuantity&&report.salesNormalQuantity.count,
            salesNormalQuantityHB:report.salesNormalQuantity&&report.salesNormalQuantity.HB,
            salesNormalQuantityTB:report.salesNormalQuantity&&report.salesNormalQuantity.TB,
            salesRetailQuantityCount:report.salesRetailQuantity&&report.salesRetailQuantity.count,
            salesRetailQuantityHB:report.salesRetailQuantity&&report.salesRetailQuantity.HB,
            salesRetailQuantityTB:report.salesRetailQuantity&&report.salesRetailQuantity.TB,
          });
          this.TableLength = this.data.length
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    getSalesCategoryScaleReportData() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          documentType: "",
          startDate: this.dates[0] || "",
          endDate: this.dates[1] || ""
        },
        page: {
          pageNo: 1,
          pageSize: 10
        },
        loginType: "CS"
      };
      if (!data.terms.orgCode) {
        data.terms.orgCode = "";
      }
      if (!data.terms.materialGroupCode) {
        data.terms.materialGroupCode = "";
      }
      this.showLoading = true;
      getSalesCategoryScaleReport(data)
        .then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          data.report = data.report || [];
          data.report = data.report.map(item => {
            return {
              name: item.categoryName,
              value: item.amount,
              color: item.color,
              scale: item.scale
            };
          });
          this.salesCategoryScaleReportData = data.report;
          this.initChart();
        })
        .finally(() => {
          this.showLoading = false;
        });
    }
  }
};
