import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";

//销售占比结构图
export function getSalesCategoryScaleReport(data) {
    return dmshttp({
        url: 'salesCategoryScaleReport',
        data
    });
}
//销售走势
export function getSalesCurrMonthReport(data) {
    return dmshttp({
        url: 'salesCurrMonthReport',
        data
    });
}
//分销商排名
export function getSalesRankDistributorReport(data) {
    return dmshttp({
        url: 'salesRankDistributorReport',
        data
    });
}
//门店排名
export function getSalesStatsReport(data) {
    return dmshttp({
        url: 'salesStatsReport',
        data
    });
}
//门店排名
export function getSalesRankStoreReport(data) {
    return dmshttp({
        url: 'salesRankStoreReport',
        data
    });
}
//我的库存列表导出
export function exportStock(data,url) {
    return dmshttp({
        url: url,
        data,
        responseType: 'blob'
    });
}
//我的库存列表
export function getStockold(data) {
    return dmshttp({
        url: "findInventoryList",
        data
    });
}
//我的库存列表
export function findBaseData(data) {
    return dmshttp({
        url: "findBaseData",
        data
    });
}
//我的库存列表
export function findMaterialGroupBySupplier(data) {
    return dmshttp({
        url: "findMaterialGroupBySupplier",
        data
    });
}
//获取分销商
export function fxDictCisCode(data) {
    return http({
        method: "post",
        url: "/comm/queryOrg.nd",
        data
    });
}
//获取分销商
export function queryMatkl(data) {
    return http({
        method: "post",
        url: "/comm/queryMatkl.nd",
        data
    });
}